<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <el-select
      v-model="selectValue"
      v-cancel-read-only="filter.filterable || false"
      class="w-full"
      clearable
      multiple
      autocorrect="off"
      autocapitalize="off"
      autocomplete="nope"
      spellcheck="false"
      popper-append-to-body
      :no-data-text="$t('common.data.no_data')"
      :remote="isRemote ? true : undefined"
      :reserve-keyword="isRemote ? true : undefined"
      :remote-method="isRemote ? searchResource : undefined"
      :filterable="filter.filterable"
      :disabled="filter.disabled || false"
      :placeholder="filter.placeholder"
      @focus="handleSelectFocus"
    >
      <div ref="optionsWrapper" v-loading="isResourceSearchPaginating">
        <span v-if="!options.length">{{ $t('common.data.no_data') }}</span>
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
        <div data-selector="optionsEnd" />
      </div>
    </el-select>
  </div>
</template>

<script setup>
import pick from 'just-pick'
import {
  defineProps,
  defineEmits,
  ref,
  computed,
  inject,
  nextTick,
  onMounted
} from 'vue'
import th from '@tillhub/javascript-sdk'

const props = defineProps({
  filter: {
    type: Object,
    required: true
  },
  modelValue: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['update:modelValue', 'resource-set'])

const apiNext = ref(null)
const optionsWrapper = ref(null)
const logException = inject('logException')
const isScrollHandlerInited = ref(false)
const isResourceSearchLoading = ref(false)
const isResourceSearchPaginating = ref(false)
const isResourceSearchPreloadDone = ref(false)
const remoteDataList = ref([])

const isRemote = computed(
  () => props.filter.resource && props.filter.fetchHandler
)
const computedFields = computed(() => props.filter.computedFields || ['name'])
const optionsValue = computed(() => props.filter.optionsValue || 'id')
const options = computed(() => {
  if (isRemote.value) {
    return remoteDataList.value.map((item) => {
      return {
        label: computeLabel(item, computedFields.value),
        value: item[optionsValue.value]
      }
    })
  }
  return props.filter.options ?? []
})

const selectValue = computed({
  get: () => {
    return props.modelValue || []
  },
  set: (value) => {
    emit('update:modelValue', value)
  }
})

onMounted(() => {
  if (isRemote.value && props.filter.doInitialFetch) {
    searchResource()
  }
})

function computeLabel(item, fields) {
  return Object.entries(pick(item, [...fields]))
    .filter((item) => item[1] !== null && item[1] !== undefined)
    .map((item) => item[1])
    .join(' - ')
    .trim()
}

const hasEnoughVisibleProducts = computed(() => options.value.length >= 15)

async function handleSelectFocus() {
  if (!isRemote.value) return

  if (!props.filter.doInitialFetch && !isResourceSearchPreloadDone.value) {
    await searchResource()
    isResourceSearchPreloadDone.value = true
  }

  if (!isScrollHandlerInited.value) {
    nextTick(() => {
      const scroller = optionsWrapper.value.parentNode.parentNode

      isScrollHandlerInited.value = true
      scroller.addEventListener('scroll', (event) => {
        const optionsEnd = optionsWrapper.value.querySelector(
          '[data-selector="optionsEnd"]'
        )
        if (!optionsEnd) return
        if (scroller.scrollTop + scroller.offsetHeight > optionsEnd.offsetTop) {
          paginateResourceSearch()
        }
      })
    })
  }
}

async function searchResource(q) {
  isResourceSearchLoading.value = true
  try {
    const { data = [], next } = await fetch(q)
    apiNext.value = next || null
    remoteDataList.value = data
    emit('resource-set', remoteDataList.value)
    if (!hasEnoughVisibleProducts.value) {
      await paginateResourceSearch()
    }
  } catch (error) {
    logException(error)
  }
  isResourceSearchLoading.value = false
}

async function paginateResourceSearch() {
  if (!apiNext.value || isResourceSearchPaginating.value) return
  isResourceSearchPaginating.value = true
  const { data, next } = await apiNext.value()
  apiNext.value = next || null
  remoteDataList.value.push(...data)
  emit('resource-set', remoteDataList.value)
  isResourceSearchPaginating.value = false

  if (!hasEnoughVisibleProducts.value && apiNext.value) {
    await paginateResourceSearch()
  }
}

async function fetch(q) {
  const resource = props.filter.resource
  const fetchHandler = props.filter.fetchHandler || 'getAll'
  if (!th[resource]) {
    const error = new Error(`${resource} is not an instantiable resource`)
    throw error
  }

  const inst = th[resource]()

  if (typeof inst[fetchHandler] !== 'function') {
    const error = new Error(
      `${fetchHandler} for resource ${resource} is not a function`
    )
    throw error
  }

  // Get query params
  const query =
    props.filter.modifyQuery && fetchHandler !== 'get'
      ? props.filter.modifyQuery(q)
      : { q }

  return await inst[fetchHandler](query)
}
</script>
